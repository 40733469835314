<div class="header-row">
  <img src="../../../assets/img/header-rgb.png" />
</div>
<div [ngClass]="helperService.isMobile ? 'padding-mobile md:px-8' : 'lg:px-16'" class="pt-4 wrapper">
  <div class="grid grid-cols-2 mb-8 gap-2 col-content-bottom">
    <div class="col-1">
      <img *ngIf="!showRelatedProductOverlay" [routerLink]="isOnline ? '/startseite' : null" class="icon-img cursor-pointer" src="{{ logoPath }}" />
      <img
        *ngIf="showRelatedProductOverlay"
        [routerLink]="isOnline ? '/startseite' : null"
        class="icon-img cursor-pointer"
        src="assets/img/bosch_logo.png"
      />
    </div>
    <div class="col-2">
      <div class="grid grid-cols-5 float-right gap-2 justify-items-end">
        <div *ngFor="let i of [].constructor(getEmptyMenuCols())" class="icon-col bg-default-col-bg empty-col"></div>

        <div *ngIf="showBack" class="items-center icon-col bg-default-col-bg" (click)="onBackClick()">
          <img
            class="menu-icon"
            [ngStyle]="{ width: helperService.isMobile && !helperService.isTablet ? '0.6rem' : '1.2rem' }"
            src="assets/menuIcons/backIcon.svg"
          />
        </div>
        <div *ngIf="showSearch" (click)="openSearchOverlay()" class="items-center icon-col bg-default-col-bg">
          <img class="menu-icon" src="assets/menuIcons/search.svg" />
        </div>
        <div
          *ngIf="showBurger"
          [ngClass]="{ 'order-1': helperService.isMobile }"
          class="icon-col items-center bg-default-col-bg"
          (click)="onBurgerClick()"
        >
          <img *ngIf="helperService.isMobile && showPageList" class="menu-icon" src="assets/icon/icon-close.png" />
          <img *ngIf="!helperService.isMobile || (helperService.isMobile && !showPageList)" class="menu-icon" src="assets/menuIcons/burgerMenu.svg" />
        </div>

        <div
          *ngIf="showShare && !helperService.isMobile && !helperService.isTablet && !helperService.isTabletLandscape && !helperService.isTablet4k"
          class="items-center icon-col bg-default-col-bg"
          (click)="onShareClick()"
        >
          <img class="menu-icon" src="assets/menuIcons/shareIcon.svg" />
        </div>
        <div
          *ngIf="showShare && (helperService.isTabletLandscape || helperService.isMobile || helperService.isTablet4k)"
          class="items-center icon-col bg-default-col-bg"
          (click)="navigateToShop()"
        >
          <img class="menu-icon" src="assets/menuIcons/mobileShareIcon.svg" />
        </div>

        <div *ngIf="showHome" class="items-center icon-col bg-default-col-bg" (click)="onHomeClick()">
          <img
            class="menu-icon"
            [ngStyle]="{ width: helperService.isMobile && !helperService.isTablet ? '1rem' : '1.6rem' }"
            src="assets/menuIcons/homeIcon.svg"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="showPageList"
  [ngClass]="
    helperService.isMobile && !helperService.isTablet
      ? 'absolute w-full padding-mobile md:px-8'
      : helperService.isTablet
      ? 'absolute right-0 w-1/3  padding-mobile md:px-8'
      : 'px-16 absolute right-0 top-99'
  "
  class=""
>
  <ul class="bg-black relative z-12 text-white text-2xl video-world-list">
    <li [ngClass]="router.url === '/sallys-highlights' ? 'font-bold' : 'font-medium'" routerLink="/sallys-highlights">
      <p class="block current-menu-item-text center-content">Sallys Highlights</p>
    </li>
    <li [ngClass]="router.url === '/sensortechnologie' ? 'font-bold' : 'font-medium'" routerLink="/sensortechnologie">
      <p class="block current-menu-item-text center-content">Sensortechnologie</p>
    </li>
    <li [ngClass]="router.url === '/vernetztes-zuhause' ? 'font-bold' : 'font-medium'" routerLink="/vernetztes-zuhause">
      <p class="block current-menu-item-text center-content">Home Connect</p>
    </li>
    <li
      [ngClass]="router.url === '/alle-funktionen' || router.url === '/funktionsauswahl' ? 'font-bold' : 'font-medium'"
      routerLink="/alle-funktionen"
    >
      <p class="block current-menu-item-text center-content">Alle Funktionen</p>
    </li>
    <!-- <li [ngClass]="router.url === '/video-library' ? 'font-bold' : 'font-medium'" routerLink="/video-library">
      <p class="block current-menu-item-text center-content">Videowelt</p>
    </li> -->
  </ul>
</div>
<app-related-products-overlay
  [selectedProduct]="selectedProduct"
  [url]="url"
  (closeRelatedProductOverlay)="closeRelatedProductOverlay()"
  *ngIf="showRelatedProductOverlay"
>
</app-related-products-overlay>
