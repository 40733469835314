<div class="max-w max-h-max overflow-hidden absolute inset-0 background-blur justify-center items-center z-10" (click)="onClickCloseAnlockScreen()">
  <!-- Bosch logo & rgb header stripe is only shown in portrait and in non-video slides of landscape slideshow -->
  <!-- Bosch logo & rgb header stripe is only shown in portrait and in non-video slides of landscape slideshow -->
  <ng-container *ngIf="(helperService.isLandscape && isNotEvenSlide()) || !helperService.isLandscape">
    <div class="header-row">
      <img class="custom-height-rgb w-full" src="../../../assets/img/header-rgb.png" />
    </div>
    <div class="absolute left-12 top-12 w-72 z-10">
      <img class="icon-img cursor-pointer" src="assets/img/bosch_logo.png" />
    </div>
  </ng-container>
  <app-background></app-background>

  <!-- SWIPER START -->
  <!-- SWIPER START -->
  <swiper (swiper)="onSwiper()" (slideChange)="onSlideChange()" #screensaverSwiper class="screensaverSwiper absolute inset-0 pointer-events-none">
    <!-- PORTRAIT SLIDES -->
    <!-- PORTRAIT SLIDES -->
    <ng-container *ngIf="!helperService.isLandscape">
      <!--       <ng-template swiperSlide>
        <div *ngIf="currentSlide === 0" class="relative top-56">
         
          <video class="w-full" loop autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" #videoPlayer>
            <source src="assets/video/fanta_vier_gesamt.mp4" type="video/mp4" />
            Browser not supported
          </video>
          <img src="assets/img/screensaver/01-Hoch-likeabosch.jpg" alt="Fanta Vier" />
        </div>
        <img
          *ngIf="currentSlide === 0"
          class="absolute right-12 touch-icon-circle-position w-64"
          src="assets/img/screensaver/touchme_symbol_circle_purple.svg"
          alt="Touch"
        />
      </ng-template> -->
      <ng-template swiperSlide>
        <img src="assets/img/screensaver/02-Hoch.jpg" />
      </ng-template>

      <ng-template swiperSlide>
        <!-- ngIf to remove the video when its not the current slide so we have no audio collision (needed on each video with its index) -->
        <!-- ngIf to remove the video when its not the current slide so we have no audio collision (needed on each video with its index) -->
        <video *ngIf="currentSlide === 1" class="h-full" loop autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" #videoPlayer>
          <source src="assets/video/backofen_hoch.mp4" type="video/mp4" />
          Browser not supported
        </video>
        <img class="absolute right-20 touch-icon-position h-28 w-auto" src="assets/icon/touchme_symbol.svg" alt="Touch" />
      </ng-template>

      <ng-template swiperSlide>
        <img src="assets/img/screensaver/04-Hoch.jpg" />
      </ng-template>

      <!--   <ng-template swiperSlide>
        <div *ngIf="currentSlide === 4" class="relative top-56">
          <video class="w-full" loop autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" #videoPlayer>
            <source src="assets/video/fanta_vier_ofen.mp4" type="video/mp4" />
            Browser not supported
          </video>
          <img src="assets/img/screensaver/01-Hoch-likeabosch.jpg" alt="Touch" />
        </div>
        <img class="absolute right-12 touch-icon-circle-position w-64" src="assets/img/screensaver/touchme_symbol_circle_blue.svg" alt="Touch" />
      </ng-template> -->

      <ng-template swiperSlide>
        <!-- ngIf to remove the video when its not the current slide so we have no audio collision (needed on each video with its index) -->
        <!-- ngIf to remove the video when its not the current slide so we have no audio collision (needed on each video with its index) -->
        <video *ngIf="currentSlide === 3" class="h-full" loop autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" #videoPlayer>
          <source src="assets/video/backofen_hoch.mp4" type="video/mp4" />
          Browser not supported
        </video>
        <img class="absolute right-20 touch-icon-position h-28 w-auto" src="assets/icon/touchme_symbol.svg" alt="Touch" />
      </ng-template>
    </ng-container>

    <!-- LANDSCAPE SLIDES -->
    <!-- LANDSCAPE SLIDES -->
    <!-- LANDSCAPE SLIDES -->
    <!-- LANDSCAPE SLIDES -->
    <ng-container *ngIf="helperService.isLandscape">
      <!--  <ng-template swiperSlide>
        <div *ngIf="currentSlide === 0">
          <video class="w-full" loop autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" #videoPlayer>
            <source src="assets/video/fanta_vier_gesamt.mp4" type="video/mp4" />
            Browser not supported
          </video>
          <img class="absolute left-1/2 transform -translate-x-1/2 bottom-32 h-28 w-auto" src="assets/icon/touchme_symbol.svg" alt="Touch" />
        </div>
      </ng-template> -->

      <ng-template swiperSlide>
        <img src="assets/img/screensaver/02-Quer.jpg" />
      </ng-template>

      <ng-template swiperSlide>
        <!-- ngIf to remove the video when its not the current slide so we have no audio collision (needed on each video with its index) -->
        <!-- ngIf to remove the video when its not the current slide so we have no audio collision (needed on each video with its index) -->
        <video *ngIf="currentSlide === 1" class="h-full" loop autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" #videoPlayer>
          <source src="assets/video/backofen_quer.mp4" type="video/mp4" />
          Browser not supported
        </video>
        <img class="absolute left-1/2 transform -translate-x-1/2 bottom-32 h-28 w-auto" src="assets/icon/touchme_symbol.svg" alt="Touch" />
      </ng-template>
      <ng-template swiperSlide>
        <img src="assets/img/screensaver/04-Quer.jpg" />
      </ng-template>

      <!--       <ng-template swiperSlide>
        <div *ngIf="currentSlide === 4">
          <video class="w-full" loop autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" #videoPlayer>
            <source src="assets/video/fanta_vier_ofen.mp4" type="video/mp4" />
            Browser not supported
          </video>
          <img class="absolute left-1/2 transform -translate-x-1/2 bottom-32 h-28 w-auto" src="assets/icon/touchme_symbol.svg" alt="Touch" />
        </div>
      </ng-template> -->

      <ng-template swiperSlide>
        <!-- ngIf to remove the video when its not the current slide so we have no audio collision (needed on each video with its index) -->
        <!-- ngIf to remove the video when its not the current slide so we have no audio collision (needed on each video with its index) -->
        <video *ngIf="currentSlide === 3" class="h-full" loop autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" #videoPlayer>
          <source src="assets/video/backofen_quer.mp4" type="video/mp4" />
          Browser not supported
        </video>
        <img class="absolute left-1/2 transform -translate-x-1/2 bottom-32 h-28 w-auto" src="assets/icon/touchme_symbol.svg" alt="Touch" />
      </ng-template>
    </ng-container>
  </swiper>
</div>
<!-- SWIPER END -->
<div *ngIf="!helperService.isMobile" (click)="onClickTurnSoundOnOff()" class="sound-on-off z-12 center-content">
  <p class="text-white" *ngIf="!videoPlayerIsMuted && showVolumeState">Volume on</p>
  <p class="text-white" *ngIf="videoPlayerIsMuted && showVolumeState">Volume off</p>
</div>
